a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
blockquote,
big,
body,
center,
canvas,
caption,
cite,
code,
datalist,
dd,
del,
details,
dfn,
dl,
div,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
keygen,
label,
legend,
li,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
source,
strike,
strong,
sub,
sup,
table,
tbody,
tfoot,
thead,
th,
tr,
td,
video,
tt,
u,
ul,
var {
	background: transparent;
	font-size: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: top;
	box-sizing: border-box
}

blockquote,
q {
	quotes: none
}

table,
table td {
	padding: 0;
	border: none;
	border-collapse: collapse;
	position: relative;
}

article,
aside,
audio,
canvas,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
keygen,
meter,
nav,
output,
progress,
section,
source,
video {
	display: block
}

mark,
rp,
rt,
ruby,
summary,
time {
	display: inline
}

* {
	box-sizing: border-box
}