.icon.icon-performance-log:before {
	content: "\eb8c";
}

.main-tooltip {
	display: none;
	position: absolute;
	z-index: 10000;
	text-align: left;
	top: 0;
	left: 0;
	font-size: 12px;
	font-size: 0.75rem;
	font-weight: normal;
}

.main-tooltip .arrow {
	position: absolute;
	width: 0;
	height: 0;
	display: block;
	border-color: transparent;
	border-style: solid;
}

.main-tooltip .arrow.top {
	top: 100%;
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000;
}

.main-tooltip .arrow.right {
	top: 50%;
	left: -5px;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #000;
}

.main-tooltip .arrow.bottom {
	top: -5px;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000;
}

.main-tooltip .arrow.left {
	top: 50%;
	right: -5px;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #000;
}

.main-tooltip .content {
	display: block;
	color: #fff;
	text-align: left;
	background-color: #000;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	border-radius: 4px;
	word-break: break-word;
	/* Force tooltip style */
	padding: 5px 10px;
	font-family: "muli", sans-serif;
}

.admin .queryLog .logBlock table.fixedtop td,
.admin .queryLog .logBlock table.fixedtop th,
.admin .performanceLog .logBlock table.fixedtop td,
.admin .performanceLog .logBlock table.fixedtop th {
	min-width: auto;
	max-width: none;
}

.admin .queryLog .logBlock a.jsLogAction,
.admin .performanceLog .logBlock a.jsLogAction {
	display: inline-block;
	margin: 0px 5px 0px 5px;
}

.admin .queryLog .jsTip:hover .main-tooltip,
.admin .performanceLog .jsTip:hover .main-tooltip {
	display: block;
	top: 100%;
	white-space: nowrap;
}

.admin .queryLog .legend,
.admin .performanceLog .legend {
	border: 1px solid black;
	display: inline-block;
	padding: 5px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.admin .queryLog .legend .row div,
.admin .performanceLog .legend .row div {
	display: inline-block;
}

.admin .queryLog .legend .box,
.admin .performanceLog .legend .box {
	display: inline-block;
	width: 14px;
	height: 14px;
	margin-right: 10px;
}

.admin .queryLog .legend .colorGood,
.admin .performanceLog .legend .colorGood {
	background: #009900;
}

.admin .queryLog .legend .colorNeutral,
.admin .performanceLog .legend .colorNeutral {
	background: #000000;
}

.admin .queryLog .legend .colorNotGood,
.admin .performanceLog .legend .colorNotGood {
	background: #FF8000;
}

.admin .queryLog .legend .colorBad,
.admin .performanceLog .legend .colorBad {
	background: #ff0000;
}

.admin .queryLog a.btn.btn-success:hover,
.admin .queryLog .btn.btn-success:hover,
.admin .queryLog a.btn.btn-success.active,
.admin .queryLog .btn.btn-success.active,
.admin .performanceLog a.btn.btn-success:hover,
.admin .performanceLog .btn.btn-success:hover,
.admin .performanceLog a.btn.btn-success.active,
.admin .performanceLog .btn.btn-success.active {
	background-color: #5dbb5c;
}

.admin .queryLog a.btn.btn-success,
.admin .queryLog .btn.btn-success,
.admin .performanceLog a.btn.btn-success,
.admin .performanceLog .btn.btn-success {
	background-color: #81ca80;
	border-color: #5dbb5c;
}

.admin .queryLog a.btn.btn-inline,
.admin .queryLog .btn.btn-inline,
.admin .performanceLog a.btn.btn-inline,
.admin .performanceLog .btn.btn-inline {
	display: inline-block;
	padding: 6px 12px;
	margin: 0 2px;
	font-family: "Source Sans Pro", sans-serif;
}

.admin .queryLog a,
.admin .queryLog a:link,
.admin .queryLog a:visited,
.admin .performanceLog a,
.admin .performanceLog a:link,
.admin .performanceLog a:visited {
	color: #777;
	text-decoration: none;
}

.admin .queryLog .page-block .page-body,
.admin .performanceLog .page-block .page-body {
	position: relative;
	padding: 15px;
}

.admin .queryLog .logRows td .icon,
.admin .performanceLog .logRows td .icon {
	margin: 0 5px;
}

.admin .queryLog table.datatbl,
.admin .performanceLog table.datatbl {
	border-collapse: collapse;
	border: 1px solid #ddd;
	border-top-color: #dddddd;
	border-top-style: solid;
	border-top-width: 1px;
}

.admin .queryLog table.datatbl .bg-warning,
.admin .performanceLog table.datatbl .bg-warning {
	background-color: #fe8d45 !important;
}

.admin .queryLog table.datatbl .bg-danger,
.admin .performanceLog table.datatbl .bg-danger {
	background-color: #e96562 !important;
}

.admin .queryLog table.datatbl .bg-success,
.admin .performanceLog table.datatbl .bg-success {
	background-color: #81ca80 !important;
}

.admin .queryLog table.datatbl th,
.admin .performanceLog table.datatbl th {
	font-size: 1rem;
	padding: 15px 5px;
	text-align: left;
	vertical-align: bottom;
	font-family: "muli-bold", sans-serif;
	background: #f9f9f9;
	cursor: pointer;
	padding-left: 15px;
	white-space: nowrap;
	border-collapse: collapse;
	border: 1px solid #ddd;
	border-top-color: #dddddd;
	border-top-style: solid;
	border-top-width: 1px;
}

.admin .queryLog table.datatbl th div,
.admin .performanceLog table.datatbl th div {
	display: inline-block;
	min-width: 10px;
}

.admin .queryLog table.datatbl th.sorted div,
.admin .performanceLog table.datatbl th.sorted div {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent #007bff transparent;
}

.admin .queryLog table.datatbl th.sortedInverse div,
.admin .performanceLog table.datatbl th.sortedInverse div {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 5px;
	border-color: #007bff transparent transparent transparent;
}

.admin .queryLog table.datatbl th:first-child,
.admin .queryLog table.datatbl td:first-child,
.admin .performanceLog table.datatbl th:first-child,
.admin .performanceLog table.datatbl td:first-child {
	min-width: 300px;
	max-width: 300px;
	white-space: normal;
	word-break: break-word;
}

.admin .queryLog table.datatbl.dbTable .nr,
.admin .performanceLog table.datatbl.dbTable .nr {
	max-width: 60px;
	min-width: 60px;
}

.admin .queryLog table.datatbl.dbTable th.query,
.admin .performanceLog table.datatbl.dbTable th.query {
	max-width: 600px;
	min-width: 600px;
}

.admin .queryLog table.datatbl.dbTable td.query,
.admin .performanceLog table.datatbl.dbTable td.query {
	max-width: 600px;
	min-width: 600px;
	text-align: left;
}

.admin .queryLog table.datatbl.dbTable th.path,
.admin .queryLog table.datatbl.dbTable td.path,
.admin .performanceLog table.datatbl.dbTable th.path,
.admin .performanceLog table.datatbl.dbTable td.path {
	width: 300px !important;
	max-width: 300px !important;
	min-width: 300px !important;
}

.admin .queryLog table.datatbl td,
.admin .performanceLog table.datatbl td {
	font-size: 1rem;
	padding: 15px 5px;
	position: relative;
	border-collapse: collapse;
	border: 1px solid #ddd;
	border-top-color: #dddddd;
	border-top-style: solid;
	border-top-width: 1px;
}

.admin .queryLog table.datatbl td .diff,
.admin .performanceLog table.datatbl td .diff {
	position: absolute;
	left: 0;
	bottom: 0;
	font-size: 10px;
	padding: 1px 2px;
	min-width: 30px;
	color: #ffffff;
}

.admin .queryLog table.datatbl td .truncQuery,
.admin .performanceLog table.datatbl td .truncQuery {
	display: none;
}

.admin .queryLog table.datatbl td .fullQuery,
.admin .performanceLog table.datatbl td .fullQuery {
	font-family: monospace;
	max-height: 2.8em;
	overflow: hidden;
}

.admin .queryLog table.datatbl td .fullQuery.show,
.admin .performanceLog table.datatbl td .fullQuery.show {
	max-height: 45em;
	overflow: auto;
	white-space: pre;
}

.admin .queryLog table.fixedtop thead,
.admin .performanceLog table.fixedtop thead {
	display: block;
	width: 100%;
}

.admin .queryLog table.fixedtop tbody,
.admin .performanceLog table.fixedtop tbody {
	display: block;
	width: 100%;
	height: 600px;
	overflow: auto;
	overflow-x: hidden;
}

.admin .queryLog table.fixedtop td,
.admin .queryLog table.fixedtop th:not(:first-child),
.admin .performanceLog table.fixedtop td,
.admin .performanceLog table.fixedtop th:not(:first-child) {
	min-width: 120px;
	max-width: 120px;
}

.admin .queryLog .performanceLogHeader,
.admin .performanceLog .performanceLogHeader {
	display: inline-block;
}

.admin .queryLog .performanceLogHeader h1,
.admin .performanceLog .performanceLogHeader h1 {
	margin: 0px;
	padding: 0px;
	font-size: 25px;
	font-weight: bold;
}

.admin .queryLog .performanceLogHeader #datepicker,
.admin .performanceLog .performanceLogHeader #datepicker {
	width: 142px;
	text-align: center;
	padding: 0;
	margin: 0;
	border: none;
	background: transparent;
	display: inline;
	font-weight: bold;
	font-family: "Lato-Regular", sans-serif;
	cursor: pointer;
	text-decoration: underline;
	font-size: 25px;
}

.admin .queryLog .performanceLogHeader span,
.admin .performanceLog .performanceLogHeader span {
	font-size: 18px;
	vertical-align: baseline;
}

.admin .queryLog .performanceLog .page-block .page-body,
.admin .performanceLog .performanceLog .page-block .page-body {
	overflow-x: auto;
}

.admin .queryLog .queryLog .tableDiv,
.admin .performanceLog .queryLog .tableDiv {
	margin-top: 15px;
}

.admin .queryLog .queryLog div.logRow,
.admin .performanceLog .queryLog div.logRow {
	padding: 5px 0;
	line-height: 26px;
}

.admin .queryLog .queryLog .logRows td,
.admin .performanceLog .queryLog .logRows td {
	padding: 5px;
	line-height: 26px;
}

.admin .queryLog .queryLog .logRows td:first-child,
.admin .performanceLog .queryLog .logRows td:first-child {
	padding-left: 0;
}

.admin .queryLog .queryLog .logRows td .icon,
.admin .performanceLog .queryLog .logRows td .icon {
	margin: 0 5px;
}

.admin .queryLog .queryLog .explainTable th,
.admin .queryLog .queryLog .explainTable td,
.admin .performanceLog .queryLog .explainTable th,
.admin .performanceLog .queryLog .explainTable td {
	padding: 5px;
}

.admin .queryLog .queryLog .explainTable i,
.admin .performanceLog .queryLog .explainTable i {
	opacity: 0.7;
}

.admin .queryLog .queryLog .explainQuery,
.admin .performanceLog .queryLog .explainQuery {
	width: 100%;
	height: 10em;
	border: 1px solid rgba(0, 0, 0, 0.5);
	overflow: auto;
	font-family: monospace;
}

.admin .queryLog .queryLog .page-body .jsGroupReport,
.admin .performanceLog .queryLog .page-body .jsGroupReport {
	float: right;
	margin-top: -25px;
}

.admin .queryLog .performanceLogOptions,
.admin .performanceLog .performanceLogOptions {
	display: inline-block;
	max-width: 340px;
	margin-left: 5px;
}

.admin .queryLog .performanceLogOptions .intro,
.admin .performanceLog .performanceLogOptions .intro {
	margin-bottom: 10px;
}

.admin .queryLog .queryLogIntro,
.admin .performanceLog .queryLogIntro {
	overflow: hidden;
}

.admin .queryLog .logBlock,
.admin .performanceLog .logBlock {
	display: inline-block;
}

.admin .queryLog .logBlock.archived,
.admin .performanceLog .logBlock.archived {
	float: right;
}

.admin .queryLog .table-responsive,
.admin .performanceLog .table-responsive {
	overflow-x: auto;
}
