@font-face {
	font-family: 'HarmoniaSansStd-SemiBd';
	src: url('fonts/HarmoniaSansStd-SemiBd.eot');
	src: url('fonts/HarmoniaSansStd-SemiBd.eot?#iefix') format('embedded-opentype'),
		 url('fonts/HarmoniaSansStd-SemiBd.woff') format('woff'),
		 url('fonts/HarmoniaSansStd-SemiBd.woff2') format('woff2'),
		 url('fonts/HarmoniaSansStd-SemiBd.ttf') format('truetype'),
		 url('fonts/HarmoniaSansStd-SemiBd.svg#HarmoniaSansStd-SemiBd') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HarmoniaSansStd-Light';
	src: url('fonts/HarmoniaSansStd-Light.eot');
	src: url('fonts/HarmoniaSansStd-Light.eot?#iefix') format('embedded-opentype'),
		 url('fonts/HarmoniaSansStd-Light.woff') format('woff'),
		 url('fonts/HarmoniaSansStd-Light.woff2') format('woff2'),
		 url('fonts/HarmoniaSansStd-Light.ttf') format('truetype'),
		 url('fonts/HarmoniaSansStd-Light.svg#HarmoniaSansStd-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'the_bold_fontbold';
	src: url('fonts/theboldfont-webfont.eot');
	src: url('fonts/theboldfont-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/theboldfont-webfont.woff2') format('woff2'),
		 url('fonts/theboldfont-webfont.woff') format('woff'),
		 url('fonts/theboldfont-webfont.ttf') format('truetype'),
		 url('fonts/theboldfont-webfont.svg#the_bold_fontbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sanslight';
	src: url('fonts/opensans-light-webfont.eot');
	src: url('fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-light-webfont.woff') format('woff'),
		 url('fonts/opensans-light-webfont.ttf') format('truetype'),
		 url('fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sansextrabold_italic';
	src: url('fonts/opensans-extrabolditalic-webfont.eot');
	src: url('fonts/opensans-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-extrabolditalic-webfont.woff2') format('woff2'),
		 url('fonts/opensans-extrabolditalic-webfont.woff') format('woff'),
		 url('fonts/opensans-extrabolditalic-webfont.ttf') format('truetype'),
		 url('fonts/opensans-extrabolditalic-webfont.svg#open_sansextrabold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sanslight_italic';
	src: url('fonts/opensans-lightitalic-webfont.eot');
	src: url('fonts/opensans-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-lightitalic-webfont.woff2') format('woff2'),
		 url('fonts/opensans-lightitalic-webfont.woff') format('woff'),
		 url('fonts/opensans-lightitalic-webfont.ttf') format('truetype'),
		 url('fonts/opensans-lightitalic-webfont.svg#open_sanslight_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sansitalic';
	src: url('fonts/opensans-italic-webfont.eot');
	src: url('fonts/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-italic-webfont.woff2') format('woff2'),
		 url('fonts/opensans-italic-webfont.woff') format('woff'),
		 url('fonts/opensans-italic-webfont.ttf') format('truetype'),
		 url('fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sansregular';
	src: url('fonts/opensans-regular-webfont.eot');
	src: url('fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-regular-webfont.woff') format('woff'),
		 url('fonts/opensans-regular-webfont.ttf') format('truetype'),
		 url('fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sansextrabold';
	src: url('fonts/opensans-extrabold-webfont.eot');
	src: url('fonts/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-extrabold-webfont.woff2') format('woff2'),
		 url('fonts/opensans-extrabold-webfont.woff') format('woff'),
		 url('fonts/opensans-extrabold-webfont.ttf') format('truetype'),
		 url('fonts/opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sansbold_italic';
	src: url('fonts/opensans-bolditalic-webfont.eot');
	src: url('fonts/opensans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-bolditalic-webfont.woff2') format('woff2'),
		 url('fonts/opensans-bolditalic-webfont.woff') format('woff'),
		 url('fonts/opensans-bolditalic-webfont.ttf') format('truetype'),
		 url('fonts/opensans-bolditalic-webfont.svg#open_sansbold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sanssemibold';
	src: url('fonts/opensans-semibold-webfont.eot');
	src: url('fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-semibold-webfont.woff') format('woff'),
		 url('fonts/opensans-semibold-webfont.ttf') format('truetype'),
		 url('fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sansbold';
	src: url('fonts/opensans-bold-webfont.eot');
	src: url('fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-bold-webfont.woff2') format('woff2'),
		 url('fonts/opensans-bold-webfont.woff') format('woff'),
		 url('fonts/opensans-bold-webfont.ttf') format('truetype'),
		 url('fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'open_sanssemibold_italic';
	src: url('fonts/opensans-semibolditalic-webfont.eot');
	src: url('fonts/opensans-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/opensans-semibolditalic-webfont.woff2') format('woff2'),
		 url('fonts/opensans-semibolditalic-webfont.woff') format('woff'),
		 url('fonts/opensans-semibolditalic-webfont.ttf') format('truetype'),
		 url('fonts/opensans-semibolditalic-webfont.svg#open_sanssemibold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumlight';
	src: url('fonts/kostic_-_argumentum-light-webfont.eot');
	src: url('fonts/kostic_-_argumentum-light-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-light-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-light-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-light-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-light-webfont.svg#argumentumlight') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumlight_italic';
	src: url('fonts/kostic_-_argumentum-lightitalic-webfont.eot');
	src: url('fonts/kostic_-_argumentum-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-lightitalic-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-lightitalic-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-lightitalic-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-lightitalic-webfont.svg#argumentumlight_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentummedium';
	src: url('fonts/kostic_-_argumentum-medium-webfont.eot');
	src: url('fonts/kostic_-_argumentum-medium-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-medium-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-medium-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-medium-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-medium-webfont.svg#argumentummedium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumitalic';
	src: url('fonts/kostic_-_argumentum-italic-webfont.eot');
	src: url('fonts/kostic_-_argumentum-italic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-italic-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-italic-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-italic-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-italic-webfont.svg#argumentumitalic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumthin';
	src: url('fonts/kostic_-_argumentum-thin-webfont.eot');
	src: url('fonts/kostic_-_argumentum-thin-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-thin-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-thin-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-thin-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-thin-webfont.svg#argumentumthin') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumthin_italic';
	src: url('fonts/kostic_-_argumentum-thinitalic-webfont.eot');
	src: url('fonts/kostic_-_argumentum-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-thinitalic-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-thinitalic-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-thinitalic-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-thinitalic-webfont.svg#argumentumthin_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentummedium_italic';
	src: url('fonts/kostic_-_argumentum-mediumitalic-webfont.eot');
	src: url('fonts/kostic_-_argumentum-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-mediumitalic-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-mediumitalic-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-mediumitalic-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-mediumitalic-webfont.svg#argumentummedium_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumregular';
	src: url('fonts/kostic_-_argumentum-regular-webfont.eot');
	src: url('fonts/kostic_-_argumentum-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-regular-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-regular-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-regular-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-regular-webfont.svg#argumentumregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumultra_light';
	src: url('fonts/kostic_-_argumentum-ultralight-webfont.eot');
	src: url('fonts/kostic_-_argumentum-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-ultralight-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-ultralight-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-ultralight-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-ultralight-webfont.svg#argumentumultra_light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumultra';
	src: url('fonts/kostic_-_argumentum-ultra-webfont.eot');
	src: url('fonts/kostic_-_argumentum-ultra-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-ultra-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-ultra-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-ultra-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-ultra-webfont.svg#argumentumultra') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumultra_italic';
	src: url('fonts/kostic_-_argumentum-ultraitalic-webfont.eot');
	src: url('fonts/kostic_-_argumentum-ultraitalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-ultraitalic-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-ultraitalic-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-ultraitalic-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-ultraitalic-webfont.svg#argumentumultra_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumblack_italic';
	src: url('fonts/kostic_-_argumentum-blackitalic-webfont.eot');
	src: url('fonts/kostic_-_argumentum-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-blackitalic-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-blackitalic-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-blackitalic-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-blackitalic-webfont.svg#argumentumblack_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumbold';
	src: url('fonts/kostic_-_argumentum-bold-webfont.eot');
	src: url('fonts/kostic_-_argumentum-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-bold-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-bold-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-bold-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-bold-webfont.svg#argumentumbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumbold_italic';
	src: url('fonts/kostic_-_argumentum-bolditalic-webfont.eot');
	src: url('fonts/kostic_-_argumentum-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-bolditalic-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-bolditalic-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-bolditalic-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-bolditalic-webfont.svg#argumentumbold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'argumentumblack';
	src: url('fonts/kostic_-_argumentum-black-webfont.eot');
	src: url('fonts/kostic_-_argumentum-black-webfont.eot?#iefix') format('embedded-opentype'),
		 url('fonts/kostic_-_argumentum-black-webfont.woff2') format('woff2'),
		 url('fonts/kostic_-_argumentum-black-webfont.woff') format('woff'),
		 url('fonts/kostic_-_argumentum-black-webfont.ttf') format('truetype'),
		 url('fonts/kostic_-_argumentum-black-webfont.svg#argumentumblack') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Chivo-ExtraBold';
	src: url('fonts/Chivo-ExtraBold.eot');
	src: url('fonts/Chivo-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('fonts/Chivo-ExtraBold.woff2') format('woff2'),
		url('fonts/Chivo-ExtraBold.woff') format('woff'),
		url('fonts/Chivo-ExtraBold.ttf') format('truetype'),
		url('fonts/Chivo-ExtraBold.svg#Chivo-ExtraBold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Chivo Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Chivo Regular'), url('fonts/Chivo-Regular.woff') format('woff'), url('fonts/Chivo-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Chivo Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Chivo Bold'), url('fonts/Chivo-Bold.woff') format('woff'), url('fonts/Chivo-Bold.ttf') format('truetype');
}
